import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { doGetTransactions } from "../store/bank_poli"
import { parseISO, format, formatDistanceToNow } from "date-fns"
import Table from "react-bootstrap/Table"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import numeral from "numeral"
import StatusBadge from "../components/StatusBadge"
import { AiOutlineReload } from "react-icons/ai"
import { refreshPMBalance } from "../store/auth"
import AvatarController from "../components/Avatars"

const formatDate = (d) => {
  if (!d) {
    return ""
  }

  let p, date_parsed

  try {
    // date_parsed = Date.parse(d)
    date_parsed = parseISO(d)
    // p = formatDistance(date_parsed, { addSuffix: true })
    p = format(date_parsed, "do MMM y 'at' h:m aaaa")
    p = formatDistanceToNow(date_parsed, { addSuffix: true })
    return p
  } catch (e) {
    console.error("could not parse", d, date_parsed)
  }
}

const UserHome = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth.user)
  const pm = useSelector((state) => state.auth.pm)
  let transactions = useSelector((state) => state.poli.transactions)

  useEffect(() => {
    dispatch(doGetTransactions())
  }, [dispatch])

  return (
    <div className="p-4">
      <h1 className="underline mb-5">Welcome {user && user.username}</h1>
      <Row className="mb-5">
        <Col sm={3}>
          <b>Avatar</b>
        </Col>
        <Col>
          <p>Choose your fighter.</p>
          <AvatarController avatar_id={pm.Avatar} />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col sm={5} lg={3} md={4}>
          <b>Balance</b>
        </Col>
        <Col>
          ${pm && pm.Balance}{" "}
          <Button
            variant="info"
            className="ms-4"
            onClick={() => {
              dispatch(doGetTransactions())
              dispatch(refreshPMBalance())
            }}
          >
            <AiOutlineReload />
          </Button>
        </Col>
      </Row>
      <h2 className="underline mb-4 mt-5">Transactions</h2>{" "}
      <div>
        {transactions && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <td>Date</td>
                <td>Source</td>
                <td>Status</td>
                <td>Amount</td>
              </tr>
            </thead>
            <tbody>
              {transactions.map((t, k) => (
                <tr key={k}>
                  <td>{formatDate(t.transaction_date)}</td>
                  <td>
                    <code>{t.source}</code>
                  </td>
                  <td>{t.status && <StatusBadge status={t.status} />}</td>
                  <td>{t.amount && numeral(t.amount).format("$0,0")}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  )
}

export default UserHome
