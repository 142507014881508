import React from "react"
import Badge from "react-bootstrap/Badge"

const StatusBadge = ({ status }) => {
  let bg = "info"

  if (!status || typeof status !== "string") {
    return <div />
  }

  if (status.toLowerCase() === "success") {
    bg = "success"
  }

  if (
    ["cancelled", "timeout", "failed", "rejected"].indexOf(
      status.toLowerCase(),
    ) > 0
  ) {
    bg = "danger"
  }

  if (status.toLowerCase() === "pending") {
    bg = "warning"
  }

  return (
    <Badge className="capitalize" pill bg={bg}>
      {status}
    </Badge>
  )
}

export default StatusBadge
