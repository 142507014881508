import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import App from "./App"
import store from "./store/index"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import "./styles/index.scss"

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://905649409164489fbe711f5b2bd0fd94@o1009722.ingest.sentry.io/5974043",
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV || process.env.REACT_APP_ENV,
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root"),
)
