import React, { useState } from "react"
import Button from "react-bootstrap/Button"

const MoneySelect = ({ onChange }) => {
  let [amount, setAmount] = useState(50)

  const incrementAmount = (incr) => {
    if (incr + amount < 501) {
      onChange(amount + incr)

      return amount + incr
    }

    return amount
  }

  const decrementAmount = (incr) => {
    if (amount - incr > 0) {
      onChange(amount - incr)
      return amount - incr
    }

    return amount
  }

  return (
    <div>
      <input
        type="text"
        // onKeyUp={() => setAmount(amount.replace(/[^\d]/, ""))}
        // {...props}
        value={amount}
        // min="25"
        // max="500"
        className="me-1"
      />
      <Button
        variant="secondary"
        className="me-1"
        size="sm"
        onClick={() => setAmount(decrementAmount(50))}
      >
        - 50
      </Button>
      <Button
        variant="secondary"
        className="me-1"
        size="sm"
        onClick={() => setAmount(incrementAmount(50))}
      >
        + 50
      </Button>
      <Button
        variant="secondary"
        className="me-1"
        size="sm"
        onClick={() => setAmount(decrementAmount(25))}
      >
        - 25
      </Button>
      <Button
        variant="secondary"
        size="sm"
        onClick={() => setAmount(incrementAmount(25))}
      >
        + 25
      </Button>
    </div>
  )
}

export default MoneySelect
