import React, { useEffect } from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { ConnectedRouter as Router } from "connected-react-router"
import Navbar from "./components/Navbar"
import PrivateRoute from "./components/PrivateRoute"
import { history } from "./store"
import LoginPage from "./pages/Login"
import RegisterPage from "./pages/Registration"
import UserPage from "./pages/UserPage"
import PlayPage from "./pages/Play"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Alert from "react-bootstrap/Alert"
import { selectAlert, setAlertClear } from "./store/alerts"
import { doUserGet } from "./store/auth"
// import { WebsocketProvier } from "./websocket"

const FooterMetadata = () => (
  <div className="footer-metadata iphone-hide">
    <p>Version: v{process.env.REACT_APP_VERSION}</p>
  </div>
)

const App = () => {
  const dispatch = useDispatch()
  const alerts = useSelector(selectAlert)

  useEffect(() => {
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(setAlertClear())
    })
  })

  useEffect(() => {
    dispatch(doUserGet(true))
  })

  return (
    <>
      <Router history={history}>
        <Navbar />
        {alerts.message && (
          <Row className="justify-content-md-center p-3">
            <Col md="8" lg="5" sm="11">
              <Alert variant={alerts.type}>{alerts.message}</Alert>
            </Col>
          </Row>
        )}
        <Switch>
          <PrivateRoute path="/user" component={UserPage} />
          <PrivateRoute exact path="/play" component={PlayPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={RegisterPage} />
          <Redirect from="*" to="/login" />
        </Switch>
        <FooterMetadata />
      </Router>
    </>
  )
}

export default App
