import React, { useEffect, useState } from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import Image from "react-bootstrap/Image"
import Form from "react-bootstrap/Form"
import { push } from "connected-react-router"

import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useLocation } from "react-router-dom"
import * as yup from "yup"

import {
  doUserLogin,
  selectIsError,
  selectIsLoading,
  selectRememberMe,
  selectUsername,
  setStoreRememberMe,
  setStoreUsername,
} from "../store/auth"

const validationSchema = yup.object().shape({
  username: yup.string().required("Username is required").min(3).max(30),
  password: yup.string().required("Password is required").min(6),
  rememberme: yup.bool(),
})

function RegisterButton() {
  const history = useHistory()

  function handleClick(path) {
    history.push(path)
  }

  return (
    <Button variant="info" onClick={() => handleClick("/register")}>
      Create Account
    </Button>
  )
}

const Login = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const isLoading = useSelector(selectIsLoading)
  const isError = useSelector(selectIsError)
  const storedUsername = useSelector(selectUsername)
  const storedRememberMe = useSelector(selectRememberMe)
  const authenticated = useSelector((state) => state.auth.authenticated)
  const pm = useSelector((state) => state.auth.pm)
  const sessionToken = useSelector((state) => state.auth.pmsession)

  let [username, setUsername] = useState(storedUsername)
  let [password, setPassword] = useState("")
  let [rememberMe, setRememberMe] = useState(storedRememberMe)
  let [errors, setErrors] = useState({})

  useEffect(() => {
    if (authenticated && pm && pm.Player && sessionToken) {
      dispatch(push("/play"))
    } else {
      console.log("not logged in", pm, sessionToken)
    }
  })

  const submitForm = (e) => {
    e.preventDefault()

    // scroll to top
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera

    const { from } = location.state || { from: { pathname: "/play" } }

    if (rememberMe) {
      dispatch(setStoreRememberMe(true))
      dispatch(setStoreUsername(username))
    } else {
      setRememberMe(false)
    }

    validationSchema
      .validate({
        username,
        password,
        rememberMe,
      })
      .then((r) => {
        dispatch(doUserLogin(username, password, from))
      })
      .catch((err) => {
        console.error(err.path, err.errors)
        setErrors({ [err.path]: err.errors[0] })
      })
  }

  return (
    <Row className="justify-content-md-center p-3">
      <Col md="6" lg="5" sm="11">
        <Card>
          <Card.Header as="h5">🔐 Log in to your account</Card.Header>
          <Card.Body>
            <p>
              Don't have an account? <Link to="/register">create one.</Link>
            </p>
            <Row className="justify-content-md-center justify-content-sm-center p-3">
              <Col xs={6} md={6}>
                <Image
                  roundedCircle
                  className="loginIcon"
                  alt=""
                  src="/icons/avatar.svg"
                />
              </Col>
            </Row>
            <Form
              name="form"
              className="w-full md:w-1/3 bg-white rounded-lg"
              onSubmit={submitForm}
            >
              <Form.Group className="mb-3" controlId="username">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  isInvalid={errors.username}
                  placeholder="Enter username"
                  autoFocus
                  readOnly={isLoading}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.username}
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                  This is your site username
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  isInvalid={errors.password || isError}
                  readOnly={isLoading}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-5" controlId="rememberMe">
                <Form.Check
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.value)}
                  label="Remember my details"
                  readOnly={isLoading}
                />
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                className="mr-9"
                disabled={isLoading}
              >
                Login
              </Button>{" "}
              <RegisterButton />
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default Login
