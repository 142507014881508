import { createSlice } from "@reduxjs/toolkit"
import { push } from "connected-react-router"
import bankService from "../services/bank"
import { setAlertError, setAlertSuccess } from "./alerts"

export const bankPoliSlice = createSlice({
  name: "poli",
  initialState: {
    institutions: undefined,
    amount: 25,
    bankId: undefined,
    isLoading: false,
    session: {},
    transactions: undefined,
  },
  reducers: {
    setInstitutions: (state, { payload }) => {
      state.institutions = payload
    },
    setAmount: (state, { payload }) => {
      state.amount = payload
    },
    setBankId: (state, { payload }) => {
      state.bankId = payload
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = !!payload
    },
    setSession: (state, { payload }) => {
      state.session = payload
    },
    setTransactions: (state, { payload }) => {
      state.transactions = payload
    },
  },
})

export const doGetInstitutions = () => (dispatch) => {
  bankService.poli.institutions().then((r) => {
    dispatch(setInstitutions(r))
  })
}

export const doGetTransactions = () => (dispatch) => {
  bankService.tx.list().then((r) => {
    dispatch(setTransactions(r))
  })
}

export const doCreatePoli = (amount, bankId) => (dispatch) => {
  dispatch(setIsLoading(true))

  console.log("here")
  bankService.poli
    .tx_create(amount, bankId)
    .then((r) => {
      console.log("s", r)
      dispatch(setSession(r))

      if (r.NavigateURL) {
        // dispatch(push(r.NavigateURL))
        console.log(r.NavigateURL)
        window.location = r.NavigateURL
      }
    })
    .catch((e) => {
      dispatch(setAlertError("Error creating transaction"))
      console.error(e)
    })
}

export const doCreatePayID = (amount) => (dispatch) => {
  dispatch(setIsLoading(true))

  bankService.payid
    .tx_create(amount)
    .then((r) => {
      dispatch(setAlertSuccess("Transaction received"))
      dispatch(push("/user"))
    })
    .catch((e) => {
      dispatch(setAlertError("Error creating transaction"))
      console.error(e)
    })
}

// Action creators are generated for each case reducer function
export const {
  setInstitutions,
  setAmount,
  setBankId,
  setIsLoading,
  setSession,
  setTransactions,
} = bankPoliSlice.actions

export default bankPoliSlice.reducer
