import React from "react"
import { useSelector } from "react-redux"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import UserHome from "./UserHome"
import UserTxPoli from "./UserTxPoli"
import { LinkContainer } from "react-router-bootstrap"
import UserTxPayid from "./UserTxPayid"
import AdminHome from "./AdminHome"

function UserPage() {
  const user = useSelector((state) => state.auth.user)
  let { path } = useRouteMatch()

  return (
    <Container fluid className="mt-4">
      <Row>
        <Col sm={4} lg={3} md={4}>
          <Card>
            <Card.Body>
              <div className="flex-column d-grid gap-2">
                <h4 className="underline">User</h4>
                <LinkContainer to={path}>
                  <Button className="text-start">Profile</Button>
                </LinkContainer>

                <h4 className="underline mt-4">Withdraw</h4>
                <LinkContainer to={`${path}/tx/poli`}>
                  <Button disabled className="text-start">
                    PayID Withdraw
                  </Button>
                </LinkContainer>
                <LinkContainer to={`${path}/tx/poli`}>
                  <Button disabled className="text-start">
                    Bitcoin Withdraw
                  </Button>
                </LinkContainer>

                <h4 className="underline mt-4">Deposit</h4>
                <LinkContainer to={`${path}/tx/poli`}>
                  <Button disabled className="text-start">
                    Poli Deposit
                  </Button>
                </LinkContainer>
                <LinkContainer to={`${path}/tx/payid`}>
                  <Button className="text-start">PayID Deposit</Button>
                </LinkContainer>
                <LinkContainer to={`${path}/tx/bitcoin`}>
                  <Button disabled className="text-start">
                    Bitcoin Deposit
                  </Button>
                </LinkContainer>

                {user.is_admin && (
                  <>
                    <h4 className="underline mt-4">Admin</h4>
                    <LinkContainer to={`${path}/admin`}>
                      <Button className="text-start">Home</Button>
                    </LinkContainer>
                  </>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={8} lg={9} md={8}>
          <Card>
            <Switch>
              <Route exact path={path}>
                <UserHome />
              </Route>
              <Route path={`${path}/tx/payid`}>
                <UserTxPayid />
              </Route>
              <Route path={`${path}/tx/poli`}>
                <UserTxPoli />
              </Route>
              <Route path={`${path}/admin/`}>
                <AdminHome />
              </Route>
            </Switch>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default UserPage
