import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import "./Avatars.scss"
import { doPMSetAvatar } from "../store/auth"
import { useDispatch } from "react-redux"

const AVATAR_SCALE = 2.5
const AVATAR_COUNT = 64

const AvatarController = ({ avatar_id = 0 }) => {
  const dispatch = useDispatch()
  let [pos, setPos] = useState(avatar_id)
  let styles = {
    backgroundPosition: `${-1 * 32 * AVATAR_SCALE * (pos - 1)}px 0px`,
  }

  const incrementPos = () => {
    if (pos < AVATAR_COUNT) {
      let newPos = parseInt(pos) + 1
      setPos(newPos)
      dispatch(doPMSetAvatar(newPos))
    }
  }

  const decrementPos = () => {
    if (pos > 1) {
      let newPos = pos - 1
      setPos(newPos)
      dispatch(doPMSetAvatar(newPos))
    }
  }

  const posHandler = (e) => {
    console.log(e.target.value)

    let v = parseInt(e.target.value)

    console.log("is", v)
    if (v >= 0 && v <= AVATAR_COUNT - 1) {
      setPos(v)
      console.log("set", v)
    }
  }

  return (
    <div className="avatar">
      <Row className="">
        <Col sm={11} md={6} lg={4}>
          <Button
            onClick={decrementPos}
            // variant="light"
            className="left"
            disabled={pos < 1}
          >
            {" "}
            <BiChevronsLeft />{" "}
          </Button>
          <span style={styles} className="previewImage" />
          <Button
            onClick={incrementPos}
            className="right"
            disabled={!!(pos > 62)}
          >
            {" "}
            <BiChevronsRight />{" "}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={11} md={6} lg={4}>
          <input
            type="range"
            value={pos}
            min="0"
            max={AVATAR_COUNT - 1}
            className="slider"
            onChange={posHandler}
          />
        </Col>
      </Row>
    </div>
  )
}

export default AvatarController
