import React from "react"
import "./PasswordStrengthMeter.scss"
import zxcvbn from "zxcvbn"

const createPasswordLabel = (result) => {
  switch (result.score) {
    case 0:
      return "Weak"
    case 1:
      return "Weak"
    case 2:
      return "Fair"
    case 3:
      return "Good"
    case 4:
      return "Strong"
    default:
      return "Weak"
  }
}

const PasswordStrengthMeter = ({ password }) => {
  let testedResult = {
    score: 0,
  }

  if (password && password.length) {
    testedResult = zxcvbn(password)
  }

  return (
    <div className="password-strength-meter">
      <progress
        className={`password-strength-meter-progress strength-${createPasswordLabel(
          testedResult,
        )}`}
        value={testedResult.score}
        max="4"
      />
      <br />
      <label className="password-strength-meter-label">
        {password && (
          <>
            <strong>Password strength:</strong>{" "}
            {createPasswordLabel(testedResult)}
          </>
        )}
      </label>
    </div>
  )
}

export default PasswordStrengthMeter
