import { createSlice } from "@reduxjs/toolkit"
import { push } from "connected-react-router"
import bankService from "../services/bank"
import { setAlertError, setAlertSuccess } from "./alerts"

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    transactions: {},
  },
  reducers: {
    setTransactions: (state, { payload }) => {
      payload.forEach((i) => {
        state.transactions[i.transaction_id] = i
      })
    },
    setTransaction: (state, { payload }) => {
      state.transactions[payload.transaction_id] = payload
    },
  },
})

export const doGetAdminTransactions = () => (dispatch) => {
  bankService.tx.listall().then((r) => {
    dispatch(setTransactions(r))
  })
}

export const doAdminApproveTransaction = (transaction_id) => (dispatch) => {
  bankService.payid
    .approve(transaction_id)
    .then((r) => {
      dispatch(setAlertSuccess("Transaction approved"))
      dispatch(setTransaction(r))
    })
    .catch((e) => {
      dispatch(setAlertError("Error approving transaction"))
      console.error(e)
    })
}

// Action creators are generated for each case reducer function
export const { setTransactions, setTransaction } = adminSlice.actions

export default adminSlice.reducer
