import React, { useState } from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { useDispatch, useSelector } from "react-redux"
import * as yup from "yup"

import { doUserRegister } from "../store/auth"
import { useHistory } from "react-router"
import PasswordStrengthMeter from "../components/PasswordStrengthMeter"

function CancelButton() {
  const history = useHistory()

  function handleClick() {
    history.push("/")
  }

  return (
    <Button variant="danger" onClick={() => handleClick()}>
      Cancel
    </Button>
  )
}

const validationSchema = yup.object().shape({
  username: yup.string().required("Username is required").min(3).max(30),
  email: yup.string().email().required("Email is required"),
  fullname: yup.string().required("Fullname is required").min(3).max(40),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase and one Number",
    )
    .required("Please enter a password"),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
})

const Login = () => {
  const dispatch = useDispatch()
  let [username, setUsername] = useState("")
  let [email, setEmail] = useState("")
  let [fullname, setFullname] = useState("")
  let [password, setPassword] = useState("")
  let [passwordConfirm, setPasswordConfirm] = useState("")
  let [errors, setErrors] = useState({
    username: undefined,
    email: undefined,
    fullname: undefined,
    password: undefined,
    passwordConfirm: undefined,
  })

  const isLoading = useSelector((state) => state.auth.isLoading)

  const submitForm = (e) => {
    e.preventDefault()

    validationSchema
      .validate({
        username,
        email,
        fullname,
        password,
        passwordConfirm,
      })
      .then((r) => {
        dispatch(doUserRegister(username, email, fullname, password))
      })
      .catch((err) => {
        setErrors({ [err.path]: err.errors[0] })
      })
  }

  console.log(errors)

  return (
    <Row className="justify-content-md-center p-3">
      <Col md="10" lg="8" sm="12">
        <Card>
          <Card.Header as="h5">Register Account</Card.Header>
          <Card.Body>
            <Form
              name="form"
              className="w-full md:w-1/3 bg-white rounded-lg"
              onSubmit={submitForm}
            >
              <Form.Group as={Row} className="mb-3" controlId="username">
                <Form.Label column sm={2}>
                  Username
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    value={username}
                    onChange={(r) => setUsername(r.target.value)}
                    isInvalid={
                      //   username &&
                      errors.username
                    }
                    placeholder="Enter username"
                    autoFocus
                    readOnly={isLoading}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.username}
                  </Form.Control.Feedback>
                  <Form.Text className="text-muted">
                    This is your site username
                  </Form.Text>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="email">
                <Form.Label column sm={2}>
                  Email
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(r) => setEmail(r.target.value)}
                    isInvalid={
                      //   formik.dirty && .email && formik.errors.email
                      errors.email
                    }
                    placeholder="Enter email"
                    readOnly={isLoading}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="fullname">
                <Form.Label column sm={2}>
                  Full Name
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    value={fullname}
                    onChange={(r) => setFullname(r.target.value)}
                    isInvalid={
                      //   formik.dirty &&
                      //   .fullname &&
                      errors.fullname
                    }
                    placeholder="Enter your full name"
                    readOnly={isLoading}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.fullname}
                  </Form.Control.Feedback>
                  <hr />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="password">
                <Form.Label column sm={2}>
                  Password
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(r) => setPassword(r.target.value)}
                    isInvalid={
                      // formik.dirty &&
                      errors.password
                    }
                    readOnly={isLoading}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                  <PasswordStrengthMeter password={password} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="passwordConfirm">
                <Form.Label column sm={2}>
                  Confirm Password
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="password"
                    placeholder="PasswordConfirm"
                    value={passwordConfirm}
                    onChange={(r) => setPasswordConfirm(r.target.value)}
                    isInvalid={
                      //   formik.dirty &&
                      //   .passwordConfirm &&
                      errors.passwordConfirm
                    }
                    readOnly={isLoading}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.passwordConfirm}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Col sm={{ span: 10, offset: 2 }}>
                  <Button
                    variant="primary"
                    type="submit"
                    className="mr-9"
                    disabled={isLoading}
                  >
                    Register
                  </Button>{" "}
                  <CancelButton />
                </Col>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default Login
