import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { parseISO, format, formatDistanceToNow } from "date-fns"
import Table from "react-bootstrap/Table"
import Button from "react-bootstrap/Button"
import numeral from "numeral"
import StatusBadge from "../components/StatusBadge"
import {
  doAdminApproveTransaction,
  doGetAdminTransactions,
} from "../store/admin"

const formatDate = (d) => {
  if (!d) {
    return ""
  }

  let p, date_parsed

  try {
    // date_parsed = Date.parse(d)
    date_parsed = parseISO(d)
    // p = formatDistance(date_parsed, { addSuffix: true })
    p = format(date_parsed, "do MMM y 'at' h:m aaaa")
    p = formatDistanceToNow(date_parsed, { addSuffix: true })
    return p
  } catch (e) {
    console.error("could not parse", d, date_parsed)
  }
}

const AdminHome = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth.user)
  let transactions = useSelector((state) =>
    Object.values(state.admin.transactions),
  )

  useEffect(() => {
    dispatch(doGetAdminTransactions())
  }, [dispatch])

  return (
    <div className="p-4">
      <h1 className="underline mb-5">Welcome {user && user.username}</h1>

      <h2 className="underline mb-4 mt-5">All Transactions</h2>
      <div>
        {transactions && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <td>Date</td>
                <td>User</td>
                <td>Source</td>
                <td>Status</td>
                <td>Amount</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {transactions.map((t, k) => (
                <tr key={k}>
                  <td>{formatDate(t.transaction_date)}</td>
                  <td>{t.username}</td>
                  <td>
                    <code>{t.source}</code>
                  </td>
                  <td>{t.status && <StatusBadge status={t.status} />}</td>
                  <td>{t.amount && numeral(t.amount).format("$0,0")}</td>
                  <td>
                    {!t.approved &&
                      t.source === "payid" &&
                      t.status === "pending" && (
                        <Button
                          variant="success"
                          onClick={() =>
                            dispatch(
                              doAdminApproveTransaction(t.transaction_id),
                            )
                          }
                        >
                          Approve
                        </Button>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  )
}

export default AdminHome
