import { createSlice } from "@reduxjs/toolkit"
import { push } from "connected-react-router"
import { api } from "../api/agent"
import { setAlertClear, setAlertError, setAlertSuccess } from "./alerts"

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    username: undefined,
    password: undefined,
    rememberMe: false,
    isError: true,
    isLoading: false,
    user: undefined,
    pmsession: undefined,
    pm: undefined,
    authenticated: undefined,
  },
  reducers: {
    setUsernameAndPassword: (state, { payload }) => {
      state.username = payload.username
      state.password = payload.password
    },
    setStoreUsername: (state, { payload }) => {
      state.username = payload
    },
    setPMSession: (state, { payload }) => {
      state.pmsession = payload
    },
    setPMAvatar: (state, { payload }) => {
      state.pm.Avatar = payload
    },
    clearPassword: (state) => {
      state.password = undefined
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = !!payload
      state.isLoading = false
    },
    setStoreRememberMe: (state, { payload }) => {
      state.rememberMe = !!payload
    },
    setIsError: (state, { payload }) => {
      state.isError = !!payload
    },
    setUser: (state, { payload }) => {
      state.user = payload
    },
    setPMUser: (state, { payload }) => {
      state.pm = payload
    },
    setPMBalance: (state, { payload }) => {
      if (state.pm) {
        state.pm.Balance = payload
      }
    },
    setLoggedIn: (state) => {
      state.authenticated = true
    },
    setLoggedOut: (state) => {
      state.authenticated = false
      state.user = undefined
      state.pm = undefined
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setUsernameAndPassword,
  setStoreUsername,
  setPMSession,
  setPMAvatar,
  clearPassword,
  setIsLoading,
  setStoreRememberMe,
  setIsError,
  setUser,
  setPMUser,
  setLoggedIn,
  setLoggedOut,
  setPMBalance,
  setAccessToken,
} = authSlice.actions

// pm user

export const refreshPMBalance = () => (dispatch) => {
  api.user.pm().then((r) => {
    dispatch(setPMBalance(r.Balance))
  })
}

export const refreshPMSession = () => (dispatch) => {
  api.user.pmsession().then((r) => {
    dispatch(setPMSession(r.SessionToken))
  })
}

export const refreshPMUser = () => (dispatch) => {
  api.user.pm().then((r) => {
    dispatch(setPMUser(r))
  })
}

export const doPMUserGet = () => (dispatch) => {
  api.user
    .pm()
    .then((r) => {
      dispatch(setPMUser(r))
    })
    .then(() => {
      dispatch(refreshPMSession())
    })
}

export const doPMSetAvatar = (id) => (dispatch) => {
  api.user.avatar(id).then((r) => {
    dispatch(setPMAvatar(id))
  })
}

// fc user

export const doUserLogout = () => (dispatch) => {
  api.auth.logout().then(() => {
    dispatch(setLoggedOut())
    dispatch(push("/"))
  })
}

export const doUserGet =
  (bypassRefresh = false) =>
  (dispatch) => {
    api.user
      .me(bypassRefresh)
      .then((r) => {
        dispatch(setLoggedIn())
        dispatch(setUser(r))
      })
      .then(() => {
        dispatch(refreshPMSession())
      })
      .then(() => {
        dispatch(doPMUserGet())
      })
      .then(() => {})
      .catch(() => {
        console.log("no user")
        dispatch(setLoggedOut())
      })
  }

export const doUserLogin = (username, password) => (dispatch) => {
  dispatch(setIsLoading(true))
  dispatch(setAlertClear())

  api.auth
    .login(username, password)
    .then((r) => {
      dispatch(clearPassword())
      dispatch(doUserGet())
    })
    .catch((e) => {
      // dispatch(setIsTrackerError(true))
      console.error(e)
      dispatch(setAlertError("invalid username or password"))
    })
    .then(() => {
      dispatch(setIsLoading(false))
    })
    .then(() => {
      dispatch(push("/play"))
    })
}

export const doUserRegister =
  (username, email, fullname, password) => (dispatch) => {
    dispatch(setIsLoading(true))
    dispatch(setAlertClear())

    api.auth
      .register(username, email, fullname, password)
      .then((r) => {
        console.log("register", r)
        dispatch(setAlertSuccess("User account registered. You can now login."))
        dispatch(push("/"))
      })
      .catch((e) => {
        // dispatch(setIsTrackerError(true))
        console.error(e)
        dispatch(
          setAlertError(
            (e.response && e.response.data && e.response.data.detail) ||
              `Request Error.`,
          ),
        )
      })
      .then(() => {
        dispatch(setIsLoading(false))
      })
  }

export const selectUsername = (state) => state.auth.username
export const selectPassword = (state) => state.auth.password
export const selectRememberMe = (state) => state.auth.rememberMe
export const selectIsLoading = (state) => state.auth.isLoading
export const selectIsError = (state) => state.auth.isError
export const selectUser = (state) => state.auth.user

export default authSlice.reducer
