import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
import { createLogger } from "redux-logger"
import { connectRouter, routerMiddleware } from "connected-react-router"
import { createBrowserHistory } from "history"

import alertsReducer from "./alerts"
import authReducer from "./auth"
import bankPoliReducer from "./bank_poli"
import adminReducer from "./admin"

const STATE_STORAGE_KEY = "s"

const logger = createLogger()

const createRootReducer = (h) =>
  combineReducers({
    alerts: alertsReducer,
    auth: authReducer,
    poli: bankPoliReducer,
    router: connectRouter(h),
    admin: adminReducer,
  })

const getStoredState = () => {
  try {
    const serialisedState = localStorage.getItem(STATE_STORAGE_KEY)
    if (serialisedState === null) return undefined
    return JSON.parse(serialisedState)
  } catch (e) {
    console.warn(e)
    return undefined
  }
}

const setStoredState = (state) => {
  let _pruned_state = {
    auth: {},
  }

  if (state?.auth?.rememberMe === true) {
    _pruned_state.auth.username = state.auth.username
  }

  _pruned_state.auth.user = state.auth.user
  _pruned_state.auth.pm = state.auth.pm
  _pruned_state.auth.pmsession = state.auth.pmsession
  // _pruned_state.auth.accessToken = state.auth.accessToken
  // _pruned_state.auth.authenticated = state.auth.authenticated
  _pruned_state.auth.rememberMe = true

  try {
    const serialisedState = JSON.stringify(_pruned_state)
    localStorage.setItem(STATE_STORAGE_KEY, serialisedState)
  } catch (e) {
    console.error(e)
  }
}

export const history = createBrowserHistory()

const routermid = routerMiddleware(history)

const store = configureStore({
  reducer: createRootReducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(logger, routermid),
  preloadedState: getStoredState(),
})

store.subscribe(() => setStoredState(store.getState()))

export default store
