import { requests, agent } from "../api/agent"

const BankService = {
  poli: {
    login: (username, password) => {
      let formData = new FormData()
      formData.append("username", username)
      formData.append("password", password)

      return requests.form(`/auth/token`, formData)
    },
    institutions: () => requests.get("/tx/poli/institutions"),
    tx_create: (amount, ficode) => {
      return agent
        .post(`/tx/poli/new`, {
          amount,
          ficode,
        })
        .then((r) => r.data)
    },
  },
  payid: {
    tx_create: (amount) => {
      return agent
        .post(`/tx/payid/new`, {
          amount,
        })
        .then((r) => r.data)
    },
    approve: (transaction_id) =>
      requests.post(`/tx/payid/approve`, { transaction_id }),
  },
  tx: {
    list: () => requests.get(`/tx/list`),
    listall: () => requests.get(`/tx/admin/list`),
    // pm: () => requests.get(`/auth/pm`),
  },
}

export default BankService
