import React from "react"
import { useDispatch, useSelector } from "react-redux"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Alert from "react-bootstrap/Alert"
import Button from "react-bootstrap/Button"
import { doCreatePayID } from "../store/bank_poli"
import { setAlertError } from "../store/alerts"
import { useState } from "react"
import MoneySelect from "../components/MoneySelect"

const UserTxPayid = () => {
  const user = useSelector((state) => state.auth.user)
  const dispatch = useDispatch()
  let [amount, setAmount] = useState(50)

  const submitForm = () => {
    if (amount < 25 || amount > 500) {
      console.error(`invalid amount ${amount}`)
      dispatch(setAlertError("Please enter a valid amount"))
      return false
    }

    dispatch(doCreatePayID(amount))
  }

  return (
    <div className="p-4">
      <h2 className="underline pb-1">Deposit with PayID</h2>
      <p>
        PayID is an instant payment method supported by most major banks. Open
        your banking app and search for "PayID" or "Pay Anyone". You send to an
        email address.
      </p>

      <h3 className="mb-4 underline mt-5">Details</h3>
      <Row>
        <Col sm={5} lg={3} md={4}>
          <b>Deposit time</b>
        </Col>
        <Col>Manually Approved</Col>
      </Row>
      <Row className="mt-3">
        <Col sm={5} lg={3} md={4}>
          <b>Max deposit</b>
        </Col>
        <Col>$500</Col>
      </Row>

      <h3 className="mt-5 underline">Instructions</h3>

      <Alert variant="warning">
        Please get the instructions exactly right otherwise your transaction may
        not be found automatically.
      </Alert>
      <Row>
        <Col sm={5} lg={3} md={4}>
          <b>Recipient:</b>
        </Col>
        <Col>
          <code>games@nikcub.me</code>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm={5} lg={3} md={4}>
          <b>Description:</b>
        </Col>
        <Col>
          <code>{user && user.username}</code>
        </Col>
      </Row>

      <h3 className="mt-5 underline">Create Transaction</h3>

      <Alert variant="warning">
        Fill this form in after you have sent your PayID.
      </Alert>

      <Row className="mb-3">
        <Col sm={5} lg={3} md={4}>
          <b>Amount:</b>
        </Col>
        <Col>
          <MoneySelect value={amount} onChange={(r) => setAmount(r)} />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col sm={5} lg={3} md={4}></Col>
        <Col>
          <Button variant="success" onClick={() => submitForm()}>
            Confirm PayID Sent
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default UserTxPayid
