import React, { useEffect, useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import Alert from "react-bootstrap/Alert"
import { useDispatch, useSelector } from "react-redux"
import { doGetInstitutions, doCreatePoli } from "../store/bank_poli"
// import { useSelector } from "react-redux"
import MoneySelect from "../components/MoneySelect"
import { setAlertError } from "../store/alerts"

const UserTxPoli = () => {
  // const user = useSelector((state) => state.auth.user)
  const dispatch = useDispatch()
  const institutions = useSelector((state) => state.poli.institutions)
  let [amount, setAmount] = useState(0)
  let [bankId, setBankId] = useState()

  useEffect(() => {
    dispatch(doGetInstitutions())
  }, [dispatch])

  const submitForm = () => {
    console.log("sub", amount, bankId)

    if (amount < 25 || amount > 500) {
      console.error(`invalid amount ${amount}`)
      dispatch(setAlertError("Please enter a valid amount"))
      return false
    }

    if (!bankId) {
      dispatch(setAlertError("Please enter your bank"))
      return false
    }

    dispatch(doCreatePoli(amount, bankId))
  }

  return (
    <div className="p-4">
      <h2 className="underline pb-1">Deposit with POLi</h2>
      <p>
        POLi is an instant online payment option allowing you to make a bank
        transfer from your internet banking site directly into your account.
      </p>

      <h3 className="mb-4 underline mt-5">Details</h3>
      <Row>
        <Col sm={5} lg={3} md={4}>
          <b>Deposit time</b>
        </Col>
        <Col>Instant</Col>
      </Row>
      <Row className="mt-3">
        <Col sm={5} lg={3} md={4}>
          <b>Max deposit</b>
        </Col>
        <Col>$500</Col>
      </Row>

      <h3 className="mt-5 underline mb-3">Create Transaction</h3>

      <Alert variant="info mb-5">
        When you click on create new deposit you will be redirected to your
        bank. It shouldn't take longer than a minute to complete.
      </Alert>

      <Row className="mb-3">
        <Col sm={5} lg={3} md={4}>
          <b>Amount:</b>
        </Col>
        <Col>
          <MoneySelect value={amount} onChange={(r) => setAmount(r)} />
        </Col>
      </Row>

      <Row>
        <Col sm={5} lg={3} md={4}>
          <b>Select your bank:</b>
        </Col>
        <Col>
          <div className="form-group">
            {institutions && (
              <select
                name="bankId"
                className="form-control"
                value={bankId}
                onChange={(e) => setBankId(e.target.value)}
              >
                <option> --- select bank --- </option>
                {institutions.map((i, k) => (
                  <option value={i.Code} key={k} disabled={!i.Online}>
                    {i.Name}
                    {!i.Online && " (offline)"}
                  </option>
                ))}
              </select>
            )}
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm={5} lg={3} md={4}></Col>
        <Col>
          <Button variant="success" onClick={() => submitForm()}>
            Create new deposit
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default UserTxPoli
