import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { push } from "connected-react-router"
import { api } from "../api/agent"

import config from "../config"
import "../styles/play.scss"
import { setAlertClear } from "../store/alerts"
import { debounce, mobileCheck } from "../utils"

const NAVBAR_HEIGHT = 58

const PlayPage = () => {
  let pm = useSelector((state) => state.auth.pm)
  let sessionToken = useSelector((state) => state.auth.pmsession)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setAlertClear())
  }, [dispatch])

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight - NAVBAR_HEIGHT,
    width: window.innerWidth,
  })

  const msgSend = (type, msg) => {
    let frame = window.frames[0]

    if (!frame) {
      console.error("Could not find frame to send message to")
      return
    }

    let msgEncoded = undefined

    try {
      msgEncoded = JSON.stringify({
        type,
        data: msg,
      })
    } catch (e) {
      console.error("Error encoding message: ", msg)
      return
    }

    frame.postMessage(msgEncoded, "*")
  }

  useEffect(() => {
    function handleResize() {
      let { width, height } = dimensions
      let isMobile = mobileCheck()
      let innerHeight = undefined

      if (width !== window.innerWidth) {
        width = window.innerWidth
      }

      if (window.innerHeight < 376) {
        isMobile = true
      }

      if (!isMobile) {
        innerHeight = window.innerHeight - NAVBAR_HEIGHT
      } else {
        innerHeight = window.innerHeight
      }

      if (height !== innerHeight) {
        height = innerHeight
      }

      setDimensions({
        height,
        width,
      })

      msgSend("resize", {
        width,
        height,
      })
    }

    window.addEventListener("resize", debounce(handleResize))
  })

  const frameOnLoad = () => {
    api.user.pmsession().then((r) => {
      console.log(r)
      msgSend("session", {
        username: r.Player,
        password: r.SessionToken,
      })
    })
  }

  let styles = {
    height: dimensions.height,
    width: dimensions.width,
  }

  if (!pm || !pm.Player || !sessionToken) {
    console.log("not logged in")
    dispatch(push("/"))
    return <div />
  } else {
    // let serverUrl = `${config.serverUrl}?LoginName=${pm.Player}&SessionKey=${pm.SessionToken}`
    let serverUrl = `${config.serverUrl}`

    return (
      <iframe
        className="playerFrame"
        onLoad={frameOnLoad}
        style={styles}
        id="game_frame"
        src={serverUrl}
        title="play"
      ></iframe>
    )
  }
}

export default PlayPage
