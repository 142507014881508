import { createSlice } from "@reduxjs/toolkit"

export const alertsSlice = createSlice({
  name: "alerts",
  initialState: {
    type: undefined,
    message: undefined,
  },
  reducers: {
    setAlertSuccess: (state, { payload }) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.type = "success"
      state.message = payload
    },
    setAlertError: (state, { payload }) => {
      state.type = "danger"
      state.message = payload
    },
    setAlertClear: (state) => {
      state.type = undefined
      state.message = undefined
    },
  },
})

// Action creators are generated for each case reducer function
export const { setAlertClear, setAlertError, setAlertSuccess } =
  alertsSlice.actions

export const selectAlert = (state) => state.alerts

export default alertsSlice.reducer
