import axios from "axios"
import createAuthRefreshInterceptor from "axios-auth-refresh"

const API_ROOT = process.env.REACT_APP_API_URL

const ALLOWED_HOSTS = ["flopchop.com", "flopchop.test"]

export const getQsParam = (name) => {
  let params = new URL(document.location).searchParams
  return params.get(name) || false
}

const getApiRoot = () => {
  const currentHost = document.location.host
  let apiRoot = API_ROOT

  if (ALLOWED_HOSTS.indexOf(currentHost) >= 0) {
    apiRoot = `//api.${currentHost}`
  }

  console.log(`apiRoot is ${apiRoot}`)

  return apiRoot
}

export const agent = axios.create({
  baseURL: getApiRoot(),
  withCredentials: true,
  timeout: 30000,
  validateStatus: function (status) {
    return status >= 200 && status < 403 && status !== 401
  },
})

createAuthRefreshInterceptor(
  agent,
  (req) => agent.post("/auth/refresh", { skipAuthRefresh: true }),
  {
    statusCodes: [401, 422],
  },
)

agent.interceptors.request.use(
  (config) => {
    config.headers = {}

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

export const handleErrors = (err) => {
  if (err && err.response && err.response.status === 401) {
    console.error(err)
  }
  return err
}

export const requests = {
  get: (url, options) => agent.get(url, options).then((r) => r.data),
  post: (url, body) => agent.post(url, body).then((r) => r.data),
  form: (url, data, options) =>
    agent({
      method: "post",
      url,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((r) => r.data),
}

export const api = {
  auth: {
    login: (username, password) => {
      let formData = new FormData()
      formData.append("username", username)
      formData.append("password", password)

      return agent({
        method: "post",
        url: "/auth/token",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        options: {
          skipAuthRefresh: true,
        },
      }).then((r) => r.data)
    },
    logout: () => agent.post("/auth/logout"),
    register: (username, email, fullname, password) =>
      requests.post(`/users/register`, {
        username,
        email,
        fullname,
        passw: password,
      }),
  },
  user: {
    me: (bypassRefresh) =>
      requests.get(`/auth/me`, { skipAuthRefresh: bypassRefresh }),
    pm: () => requests.get(`/auth/pm`),
    pmsession: () => requests.get(`/auth/pm/session`),
    avatar: (id) => requests.post(`/users/avatar`, { avatar_id: id }),
  },
}
