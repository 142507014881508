import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import Image from "react-bootstrap/Image"
import { useSelector } from "react-redux"
import { LinkContainer } from "react-router-bootstrap"
import { useDispatch } from "react-redux"
import { doUserLogout } from "../store/auth"
import {
  AiOutlineUser,
  AiOutlineLogout,
  AiOutlinePlayCircle,
  AiTwotoneVideoCamera,
} from "react-icons/ai"

const NavbarComponent = () => {
  const authenticated = useSelector((state) => state.auth.authenticated)
  const dispatch = useDispatch()
  const pm = useSelector((state) => state.auth.pm)
  const zoom_url =
    "https://us02web.zoom.us/j/89323498616?pwd=eDFvZWVDN0lVdGVoZDZxYzd0bVFFZz09"

  return (
    <Navbar
      bg="primary"
      variant="dark"
      sticky="top"
      expand="lg"
      className="iphone-hide"
    >
      <Container fluid>
        <LinkContainer to="/">
          <Navbar.Brand>
            <Image
              src="/logo_md.png"
              // width="30"
              height="32"
              className="d-inline-block align-top"
              alt="flopchop logo"
            />{" "}
          </Navbar.Brand>
        </LinkContainer>

        {authenticated && (
          <>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-end"
            >
              <Nav className="">
                {/* <LinkContainer to="/user">
                  <Button variant="info" className="me-1">
                    <b>Balance ${pm && pm.Balance}</b>
                  </Button>
                </LinkContainer> */}
                <Button
                  variant="success"
                  className="me-1"
                  onClick={() => window.open(zoom_url, "_blank").focus()}
                >
                  <AiTwotoneVideoCamera /> Zoom
                </Button>
                <LinkContainer to="/play" className="">
                  <Button variant="success" className="me-1">
                    <AiOutlinePlayCircle /> Play
                  </Button>
                </LinkContainer>
                <LinkContainer to="/user">
                  <Button>
                    <AiOutlineUser /> Profile
                  </Button>
                </LinkContainer>
                <LinkContainer to="/logout">
                  <Button onClick={() => dispatch(doUserLogout())}>
                    <AiOutlineLogout /> Logout
                  </Button>
                </LinkContainer>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  )
}

export default NavbarComponent
